import { DiEntity, DiEntityIdentifier } from '@core/di/types';
import { DI_TOKENS } from '@shared/constants/di';
import { CardsService } from '@shared/services/cards';
import { ErrorMessagesService } from '@shared/services/error-messages';
import { NotificationService } from '@shared/services/notification';
import { PaymentsService } from '@shared/services/payments/payments.service';
import { Secure3DRepositoryService } from '@shared/services/secure3d-repository';
import { SmartCoinNotificationService } from '@shared/services/smart-coin-notification';
import { SmartCoinRepositoryService } from '@shared/services/smart-coin-repository';
import { SmartCoinTrackingService } from '@shared/services/smart-coin-tracking';
import { TransactionsNotificationService } from '@shared/services/transactions-notification';
import { LayoutViewModel } from '@shared/view-models/layout';
import { LocalesViewModel } from '@shared/view-models/locales';
import { NavigationViewModel } from '@shared/view-models/navigation.vm';
import { ProductsViewModel } from '@shared/view-models/products';
import { RatesViewModel } from '@shared/view-models/rates';
import { SmartCoinDetailsDialogViewModel } from '@shared/view-models/smart-coin-details-dialog';

import { AuthService } from './auth';
import { OrdersSmartCoinService } from './order-smart-coin';
import { OrdersService } from './orders';
import { StripeStrategy } from './payments/stripe-strategy';
import { WorldpayStrategy } from './payments/worldpay-strategy';
import { ProductsService } from './products';
import { RatesService } from './rates';
import { UsersService } from './users';
import { WalletsService } from './wallets';
import { CardsCooldownCheckViewModel } from '@shared/view-models/cards-cooldown-check';

export const sharedAppServices: Array<{ diToken: DiEntityIdentifier; entity: DiEntity }> = [
  { diToken: DI_TOKENS.layoutVM, entity: LayoutViewModel },
  { diToken: DI_TOKENS.ratesVM, entity: RatesViewModel },
  { diToken: DI_TOKENS.localesVM, entity: LocalesViewModel },
  { diToken: DI_TOKENS.authService, entity: AuthService },
  { diToken: DI_TOKENS.walletsService, entity: WalletsService },
  { diToken: DI_TOKENS.ratesService, entity: RatesService },
  { diToken: DI_TOKENS.ordersService, entity: OrdersService },
  { diToken: DI_TOKENS.usersService, entity: UsersService },
  { diToken: DI_TOKENS.cardService, entity: CardsService },
  { diToken: DI_TOKENS.errorMessagesService, entity: ErrorMessagesService },
  { diToken: DI_TOKENS.worldpayStrategy, entity: WorldpayStrategy },
  { diToken: DI_TOKENS.stripeStrategy, entity: StripeStrategy },
  { diToken: DI_TOKENS.paymentService, entity: PaymentsService },
  { diToken: DI_TOKENS.productsVM, entity: ProductsViewModel },
  { diToken: DI_TOKENS.productsService, entity: ProductsService },
  { diToken: DI_TOKENS.ordersSmartCoinService, entity: OrdersSmartCoinService },
  { diToken: DI_TOKENS.smartCoinNotificationService, entity: SmartCoinNotificationService },
  { diToken: DI_TOKENS.smartCoinRepositoryService, entity: SmartCoinRepositoryService },
  { diToken: DI_TOKENS.smartCoinTrackingService, entity: SmartCoinTrackingService },
  { diToken: DI_TOKENS.notificationService, entity: NotificationService },
  { diToken: DI_TOKENS.smartCoinDetailsDialogViewModel, entity: SmartCoinDetailsDialogViewModel },
  { diToken: DI_TOKENS.transactionsNotificationService, entity: TransactionsNotificationService },
  { diToken: DI_TOKENS.secure3DRepositoryService, entity: Secure3DRepositoryService },
  { diToken: DI_TOKENS.navigationViewModel, entity: NavigationViewModel },
  { diToken: DI_TOKENS.cardsCooldownCheckVM, entity: CardsCooldownCheckViewModel },
];
