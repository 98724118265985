import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { TransactionMessageType } from '@shared/enums/transaction-message-type';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { Currency } from '@shared/models/wallets/currency';
import { smartCoinOrderStatusPipe } from '@shared/pipes/smart-coin-order-status.pipe';
import { IOrdersSmartCoinService } from '@shared/types/orders-smart-coin-service';
import { ISecure3DRepositoryService } from '@shared/types/secure-3d-repository-service';
import { ISmartCoinNotificationService } from '@shared/types/smart-coin-notification-service';
import {
  SmartCoinOrderStatusExternal,
  SmartCoinOrderStatusInternal,
} from '@shared/types/smart-coin-order-status';
import {
  ISmartCoinRepositoryService,
  LocalStorageOrdeItem,
} from '@shared/types/smart-coin-repository-service';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { ITransactionsNotificationService } from '@shared/types/transactions-notification-service';
import { IWalletsService } from '@shared/types/wallets-service';
import { formatAmount } from '@shared/utils/metals';

@appInjectable()
export class SmartCoinTrackingService implements ISmartCoinTrackingService {
  private smartCoinNotificationService = appInject<ISmartCoinNotificationService>(
    DI_TOKENS.smartCoinNotificationService,
  );
  private transactionsNotificaionService = appInject<ITransactionsNotificationService>(
    DI_TOKENS.transactionsNotificationService,
  );
  private smartCoinRepositoryService = appInject<ISmartCoinRepositoryService>(
    DI_TOKENS.smartCoinRepositoryService,
  );
  private ordersSmartCoinService = appInject<IOrdersSmartCoinService>(
    DI_TOKENS.ordersSmartCoinService,
  );
  private walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  private secure3DRepositoryService = appInject<ISecure3DRepositoryService>(
    DI_TOKENS.secure3DRepositoryService,
  );
  private subscriptions: Map<string, Function> = new Map();
  private subscriptionsExecutedOrder: Map<string, Function> = new Map();
  private shown3DSecureMessages: Map<string, boolean> = new Map();

  init() {
    setInterval(() => {
      this.trackOrders();
    }, 5000);
  }

  async startTracking(data: {
    orderId: string;
    amount: number;
    smartCoin: string;
    isBuy: boolean;
    isSend?: boolean;
    isWithdraw?: boolean;
    secure3DAlreadyShown?: boolean;
    hideFirstMessage?: boolean;
  }) {
    this.smartCoinRepositoryService.saveOrderToStorage({
      orderId: data.orderId,
      status: SmartCoinOrderStatusExternal.NEW,
      isBuy: data.isBuy,
      isSend: data.isSend || false,
      isWithdraw: data.isWithdraw || false,
      smartCoin: data.smartCoin as Currency,
      amount: data.amount,
    });

    if (data.secure3DAlreadyShown) {
      this.shown3DSecureMessages.set(data.orderId, true);
    }

    if (!data.hideFirstMessage) {
      this.smartCoinNotificationService.showNotification({
        id: data.orderId,
        status: SmartCoinOrderStatusExternal.IN_PROGRESS,
        quantity: formatAmount(data.amount, 8),
        smartCoinName: data.smartCoin,
        isBuy: data.isBuy,
      });
    }
  }

  stopTracking(orderId: string) {
    this.smartCoinRepositoryService.removeOrderFromStorage(orderId);
    this.secure3DRepositoryService.removeFromStorage(orderId);
  }

  subscribe(subscriptionId: string, cb: () => void) {
    this.subscriptions.set(subscriptionId, cb);
  }

  unsubscribe(subscriptionId: string) {
    this.subscriptions.get(subscriptionId);
  }

  subscribeForExecutedOrder(subscriptionId: string, cb: () => void) {
    this.subscriptionsExecutedOrder.set(subscriptionId, cb);
  }

  unsubscribeForExecutedOrder(subscriptionId: string) {
    this.subscriptions.get(subscriptionId);
  }

  async trackOrders() {
    for (const storedOrderItem of this.smartCoinRepositoryService.getActiveOrdersFromStrorage()) {
      if (!storedOrderItem.id) {
        this.stopTracking(storedOrderItem.id);
      }
      if (storedOrderItem.isSend || storedOrderItem.isWithdraw) {
        await this.checkTransfer(storedOrderItem);
      } else {
        await this.checkOrder(storedOrderItem);
      }
    }
  }

  private async checkOrder(storedOrderItem: LocalStorageOrdeItem) {
    const orderStatus = await this.ordersSmartCoinService.getOrderStatus(storedOrderItem.id);
    const externalStatus = smartCoinOrderStatusPipe(
      orderStatus.asJson.status as SmartCoinOrderStatusInternal,
    );

    if (
      externalStatus === SmartCoinOrderStatusExternal.SECURE_3D &&
      !this.shown3DSecureMessages.has(storedOrderItem.id)
    ) {
      this.shown3DSecureMessages.set(storedOrderItem.id, true);
      this.smartCoinNotificationService.showNotification({
        id: storedOrderItem.id,
        status: externalStatus,
        quantity: formatAmount(orderStatus.asJson.quantity, 8),
        smartCoinName: orderStatus.asJson.smartCoin,
        isBuy: storedOrderItem.isBuy,
      });
    }

    if (storedOrderItem.status !== externalStatus) {
      if (
        externalStatus !== SmartCoinOrderStatusExternal.SECURE_3D ||
        !this.shown3DSecureMessages.has(storedOrderItem.id)
      ) {
        this.smartCoinNotificationService.showNotification({
          id: storedOrderItem.id,
          status: externalStatus,
          quantity: formatAmount(orderStatus.asJson.quantity, 8),
          smartCoinName: orderStatus.asJson.smartCoin,
          isBuy: storedOrderItem.isBuy,
        });
      }
      this.smartCoinRepositoryService.updateOrderStatus(storedOrderItem.id, externalStatus);
      for (const cb of Array.from(this.subscriptions.values())) {
        cb();
      }
    }
    if (
      [SmartCoinOrderStatusExternal.EXECUTED, SmartCoinOrderStatusExternal.CANCELED].includes(
        externalStatus,
      )
    ) {
      this.stopTracking(storedOrderItem.id);
    }

    if (externalStatus === SmartCoinOrderStatusExternal.EXECUTED) {
      for (const cb of Array.from(this.subscriptionsExecutedOrder.values())) {
        cb();
      }
    }
  }

  private async checkTransfer(storedOrderItem: LocalStorageOrdeItem) {
    const transaction = await this.walletsService.getTransactionDetails(storedOrderItem.id);
    if (storedOrderItem.status !== transaction.asJson.status) {
      let visibleStatus = 'inProgress';
      switch (transaction.asJson.status) {
        case TransactionStatus.Completed:
          visibleStatus = 'successful';
          break;
        case TransactionStatus.InProgress:
          visibleStatus = 'inProgress';
          break;
        case TransactionStatus.Failed:
          if (storedOrderItem.isSend) {
            visibleStatus = 'successful';
          } else if (storedOrderItem.isWithdraw) {
            visibleStatus = 'canceled';
          }
          break;
        default:
          break;
      }

      let transactionType = TransactionMessageType.TRANSFER;
      if (storedOrderItem.isSend) {
        transactionType = TransactionMessageType.TRANSFER;
      } else {
        transactionType = TransactionMessageType.SELL_FIAT_TOKEN;
      }

      let currency;
      if (storedOrderItem.isSend) {
        currency = Currency.GSC;
      } else {
        currency = storedOrderItem.smartCoin;
      }

      this.transactionsNotificaionService.showNotification({
        id: storedOrderItem.id,
        amount: storedOrderItem.amount,
        currency: currency,
        status: visibleStatus,
        type: transactionType,
      });

      this.smartCoinRepositoryService.updateOrderStatus(
        storedOrderItem.id,
        transaction.asJson.status,
      );
      for (const cb of Array.from(this.subscriptions.values())) {
        cb();
      }
    }

    if (
      [TransactionStatus.Failed, TransactionStatus.Completed].includes(
        transaction.asJson.status as TransactionStatus,
      )
    ) {
      this.stopTracking(storedOrderItem.id);
    }

    if (storedOrderItem.status === TransactionStatus.Completed) {
      for (const cb of Array.from(this.subscriptionsExecutedOrder.values())) {
        cb();
      }
    }
  }
}
