import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import CloseButtonIcon from '@shared/icons/transactions/close-button.svg';
import { ResponsePaymentResult } from '@shared/models/orders/status-model';
import { PaymentMethodType } from '@shared/services/payments/payment-method-type.enum';
import { IConfigService } from '@shared/types/config-service';
import { NewPaymentMethodDto } from '@shared/types/payments/new-payment-method.dto';
import { PaymentProviderEnum } from '@shared/types/payments/payment-provider.enum';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';
import { ISecure3DRepositoryService } from '@shared/types/secure-3d-repository-service';
import { wait } from '@shared/utils/wait';

@appInjectable()
export class WorldpayStrategy implements IPaymentProvider {
  static create() {
    return new WorldpayStrategy();
  }

  public name: PaymentProviderEnum = PaymentProviderEnum.WORLDPAY;
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private secure3DRepositoryService = appInject<ISecure3DRepositoryService>(
    DI_TOKENS.secure3DRepositoryService,
  );
  private checkoutSDKUrl: string;
  private dialog: any;
  private errorState: boolean = false;
  private _instance: any;

  get instance() {
    return this._instance;
  }

  constructor() {
    this.checkoutSDKUrl = this.configService.worldpayCheckoutSDKUrl() || '';
    this.addEventListeners();
  }

  createPaymentMethod({
    session,
    cardHolder,
    cardType,
    cardNumber,
    isTemporary,
  }: any): Promise<NewPaymentMethodDto> {
    return Promise.resolve({
      id: session,
      cardHolder,
      paymentProvider: PaymentProviderEnum.WORLDPAY,
      isTemporary,
      paymentMethodType: PaymentMethodType.CREDIT_CARD,
      paymentMethod: {
        id: session,
        card: {
          brand: cardType,
          last4: cardNumber,
        },
      },
    });
  }

  async handle3DSecure(data: ResponsePaymentResult, orderId: string): Promise<any> {
    const rootContainer = document.getElementById('root');
    if (!rootContainer) {
      throw 'Failed creation 3D Secure IFrame';
    }
    try {
      this.secure3DRepositoryService.saveToStorage(orderId);
      this.create3DSecureIFrame(data, rootContainer);
      return await this.waitUserActions();
    } catch (ex) {
      this.secure3DRepositoryService.removeFromStorage(orderId);
      this.closeFrame();
      throw 'Failed 3D Secure';
    }
  }

  downloadSDK() {
    return this.downloadWorldpayCheckoutSDK();
  }

  private create3DSecureIFrame(data: ResponsePaymentResult, root: HTMLElement) {
    this.dialog = document.createElement('dialog');
    this.dialog.setAttribute(
      'style',
      'z-index: 999; position: fixed; left: 0px; top: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,0.5); border: none',
    );

    const closeButton = document.createElement('div');
    closeButton.setAttribute(
      'style',
      `cursor: pointer; position: absolute; right: 30px; top: 30px; width: 40px; height:40px; background: url(${CloseButtonIcon}) no-repeat left center`,
    );
    closeButton.addEventListener('click', () => {
      this.stop3DSecureProcess();
    });
    const iframe = document.createElement('iframe');
    iframe.setAttribute(
      'style',
      'padding: 15px; background-color: #fff; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
    );
    iframe.setAttribute('height', '400');
    iframe.setAttribute('width', '390');
    iframe.setAttribute('src', `${location.origin}/3d-secure?url=${data.url}&jwt=${data.jwt}`);
    this.dialog?.appendChild(closeButton);
    this.dialog?.appendChild(iframe);
    root?.appendChild(this.dialog);
    this.dialog.show();
  }

  private closeFrame() {
    if (this.dialog) this.dialog.close();
    this.dialog = null;
    this.errorState = false;
  }

  private stop3DSecureProcess() {
    this.errorState = true;
  }

  private async waitUserActions(): Promise<void> {
    for (let counter = 0; counter < 900; ) {
      await wait(1);
      if (this.errorState) throw 'Cancel';
      if (!this.dialog || !this.dialog.open) {
        return;
      }
    }
    this.closeFrame();
    return;
  }

  private addEventListeners() {
    window.addEventListener(
      'message',
      async (event) => {
        if (event.data === '3DS_SUBMITTED') {
          await wait(3);
          this.closeFrame();
        }
      },
      false,
    );
  }

  private async downloadWorldpayCheckoutSDK(): Promise<any> {
    if (!this.checkoutSDKUrl || !this.configService.worldpayCheckoutSDKId()) {
      alert('Worldpay Checkout SDK unavailable!');
      return;
    }
    const isScriptAlreadyLoaded = (src: string): boolean => {
      return Boolean(document.querySelector(`script[src="${src}"]`));
    };
    return new Promise((resolve, reject) => {
      if (isScriptAlreadyLoaded(this.checkoutSDKUrl)) {
        return resolve(true);
      }
      let checkoutScript = document.createElement('script');
      checkoutScript.src = this.checkoutSDKUrl;
      checkoutScript.onload = resolve;
      checkoutScript.onerror = reject;
      document.head.appendChild(checkoutScript);
    });
  }

  async init({
    formId,
    cardNumberFieldId,
    cardExpireFieldId,
    cvvFieldId,
    styles,
  }: any): Promise<any> {
    // @ts-ignore
    if (!window.Worldpay) {
      await this.downloadWorldpayCheckoutSDK();
    }
    return new Promise((resolve, reject) => {
      // @ts-ignore
      window.Worldpay.checkout.init(
        {
          id: this.configService.worldpayCheckoutSDKId(),
          form: formId,
          fields: {
            pan: {
              selector: cardNumberFieldId,
            },
            expiry: {
              selector: cardExpireFieldId,
            },
            cvv: {
              selector: cvvFieldId,
            },
          },
          styles,
          enablePanFormatting: true,
        },
        (error: any, checkout: any) => {
          if (error) {
            reject(error);
          } else {
            resolve(checkout);
          }
        },
      );
    });
  }
}
