import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import {
  CreateOrderDto,
  CreateOrderModel,
  CreateOrderQuery,
} from '@shared/models/orders/create-model';
import {
  SmartCoinOrderListModel,
  SmartCoinOrderListQuery,
} from '@shared/models/orders/smart-coin-order-list-model';
import {
  PagedQuery,
  PagedResponseModel,
  PagedResponseQuery,
} from '@shared/models/pagination/paged-response-model';
import {
  SmartCoinOrderDetailsModel,
  SmartCoinOrderDetailsQuery,
} from '@shared/models/smart-coin-order-details/smart-coin-order-details-model';
import {
  SmartCoinOrderStatusModel,
  SmartCoinOrderStatusQuery,
} from '@shared/models/smart-coin-order-status/smart-coin-order-status-model';
import { SmartCoinOrdersListQueryModel } from '@shared/models/smart-coin-orders-list/smart-coin-orders-list-query-model';
import { IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';
import { IOrdersSmartCoinService } from '@shared/types/orders-smart-coin-service';

@appInjectable()
export class OrdersSmartCoinService implements IOrdersSmartCoinService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(DI_TOKENS.appHttpClientService);
  private baseURL: string;

  constructor() {
    this.baseURL = this.configService.baseUrlOrders;
  }

  createPurchaseOrder = async ({
    paymentMethod,
    paymentMethodId,
    quantity,
    fiatCurrency,
    smartCoin,
    paymentTransactionId,
    deviceDataReference,
  }: CreateOrderDto): Promise<CreateOrderModel> => {
    const rateResult = await this.httpClient.post(
      '/smartCoinOrders/purchase',
      {
        paymentMethod: paymentMethod,
        paymentMethodId,
        quantity,
        fiatCurrency: fiatCurrency,
        smartCoin: smartCoin,
        paymentTransactionId,
        deviceDataReference,
        forceConfirm: true,
      },
      { baseURL: this.baseURL },
    );

    const order: CreateOrderQuery = rateResult.data as CreateOrderQuery;
    return new CreateOrderModel(order);
  };

  createSaleOrder = async ({
    paymentMethod,
    paymentMethodId,
    quantity,
    fiatCurrency,
    smartCoin,
    paymentTransactionId,
    deviceDataReference,
  }: CreateOrderDto): Promise<{ id: string }> => {
    const result = await this.httpClient.post<{ id: string }>(
      '/smartCoinOrders/sale',
      {
        paymentMethod: paymentMethod,
        paymentMethodId,
        quantity,
        fiatCurrency: fiatCurrency,
        smartCoin: smartCoin,
        paymentTransactionId,
        deviceDataReference,
      },
      { baseURL: this.baseURL },
    );
    return result.data;
  };

  confirm = async (orderId: string): Promise<void> => {
    await this.httpClient.put(
      `/smartCoinOrders/purchase/${orderId}/confirm`,
      {},
      { baseURL: this.baseURL },
    );
  };

  getOrderStatus = async (orderId: string): Promise<SmartCoinOrderStatusModel> => {
    const { data } = await this.httpClient.get<SmartCoinOrderStatusQuery>(
      `/smartCoinOrders/status/${orderId}`,
      {
        baseURL: this.baseURL,
      },
    );
    return new SmartCoinOrderStatusModel(data);
  };

  getOrderDetails = async (orderId: string): Promise<SmartCoinOrderDetailsModel> => {
    const { data } = await this.httpClient.get<SmartCoinOrderDetailsQuery>(
      `/mySmartCoinOrders/${orderId}`,
      {
        baseURL: this.baseURL,
      },
    );
    return new SmartCoinOrderDetailsModel(data);
  };

  getList = async (
    params: SmartCoinOrdersListQueryModel,
  ): Promise<PagedResponseModel<SmartCoinOrderListModel[]>> => {
    const response = await this.httpClient.get<
      PagedResponseQuery<SmartCoinOrderListQuery[] | null>
    >(`/mySmartCoinOrders`, {
      baseURL: this.baseURL,
      params: {
        OrderBy: params.orderBy,
        OrderByAscending: params.asc,
        SearchString: params.search,
        StartDate: params.search,
        EndDate: params.endDate,
        Status: params.status,
        PageNumber: params.page || 1,
        PageSize: params.perPage || 20,
      },
      paramsSerializer: (params: any) => {
        return Object.keys(params)
          .filter((key) => params[key] !== undefined)
          .map((key: string) => {
            if (key === 'Status') {
              return params[key]
                .map((value: string) => `Status=${encodeURIComponent(value)}`)
                .join('&');
            } else {
              return `${key}=${encodeURIComponent(params[key])}`;
            }
          })
          .join('&');
      },
    });
    const { data: items, ...rest } = response.data;

    const paging = rest as PagedQuery;
    const orders: SmartCoinOrderListModel[] =
      items?.map((item: SmartCoinOrderListQuery) => new SmartCoinOrderListModel(item)) || [];
    return new PagedResponseModel<SmartCoinOrderListModel[]>({ data: orders, ...paging });
  };
}
