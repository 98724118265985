import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { IAuthService } from '@shared/types/auth-service';
import { SmartCoinOrderStatusExternal } from '@shared/types/smart-coin-order-status';
import {
  ISmartCoinRepositoryService,
  LocalStorageOrdeItem, StoredOrder,
} from '@shared/types/smart-coin-repository-service';

@appInjectable()
export class SmartCoinRepositoryService implements ISmartCoinRepositoryService {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private storageFieldName = 'smartCoinOrders';

  getActiveOrdersFromStrorage(): Array<LocalStorageOrdeItem> {
    if (!this.authService.userId) return [];
    return this.getExistsOrders();
  }

  removeOrderFromStorage(orderId: string): void {
    if (!this.authService.userId) return;
    const key = this.generateKey();
    const orders = this.getExistsOrders();
    localStorage.setItem(key, JSON.stringify(orders.filter((order) => order.id !== orderId)));
  }

  saveOrderToStorage(data: StoredOrder): void {
    if (!this.authService.userId) return;
    const key = this.generateKey();
    const orders = this.getExistsOrders();
    if (orders.find((order) => order.id === data.orderId)) return;
    orders.push({
      id: data.orderId,
      status: data.status,
      isBuy: data.isBuy,
      isSend: data.isSend,
      isWithdraw: data.isWithdraw,
      amount: data.amount,
    } as LocalStorageOrdeItem);
    localStorage.setItem(key, JSON.stringify(orders));
  }

  updateOrderStatus(
    orderId: string,
    status: SmartCoinOrderStatusExternal | TransactionStatus,
  ): void {
    if (!this.authService.userId) return;
    const key = this.generateKey();
    const orders = this.getExistsOrders();
    orders.forEach((order) => order.id === orderId && (order.status = status));
    localStorage.setItem(key, JSON.stringify(orders));
  }

  private generateKey(): string {
    return `${this.storageFieldName}:${this.authService.userId}`;
  }

  private getExistsOrders(): Array<LocalStorageOrdeItem> {
    const key = this.generateKey();
    return JSON.parse(localStorage.getItem(key) || '[]');
  }
}
